/*
Theme Name: Refresh Media
Theme URI: http://www.refresh-media.nl
Author: Refresh Media
Author URI: http://www.refresh-media.nl
Description: Thema ontwikkeld voor Refresh Media.
Version: 1.7.2.
*/

@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Luckiest+Guy|Permanent+Marker');
@import '../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap';
@import "../../node_modules/hover.css/css/hover";

@import "_variables";
@import "_default";
@import "_menu-one-site"; /* Include menu one, two or shop. Also change the include in header.php. */
@import "_slideshow";
@import "_three-icons";
@import "_custom-post-type";
@import "_image-fullwidth-one";
@import "_image-fullwidth-two";
@import "_video-fullwidth";
@import "_intro-one";
@import "_accordion";
@import "_forms";

@import "../../node_modules/font-awesome/scss/font-awesome";


/* ================================
=Header
================================ */

/* ================================
=Body
================================ */

/* ----- =Intro ------ */

section.intro {
  background-color: transparent;
  background-position: center;
  background-size: cover;
  transform: translateX(-180deg);
  position: relative;
  height: 60vh;
  padding: 0;
  -webkit-backface-visibility: hidden;
  background-attachment: fixed;

  &.page {
    height: 320px;
    background-position: top;
    text-align: center;
    background-size: cover;
    background-attachment: inherit;

    .inner {
      height: 320px;

      .inner-content {
        left: 0;

        h1 {
          font-size: 50px;
          text-transform: capitalize;
          width: 100%;
          text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);

          span {
            text-transform: none;
          }
        }
      }
    }
  }

  .picture-overlay {
    @include overlay;
    background-color: rgba(0, 0, 0, 0);
  }

  .inner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    height: 60vh;
    position: relative;
    z-index: 1;
    text-align: center;

    .inner-content {
      padding: 15px;
      text-align: center;

      h1 {
        color: #fff;
        margin: 0;
        padding: 0;
        line-height: 100%;
        font-family: $font-family-alt;
        font-size: 65px;
        font-weight: 300;
        display: inline-block;
        position: relative;
        width: 100%;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      }

      p {
        font-size: 36px;
        font-family: $font-family;
        color: $bg-color-grey;
        font-weight: 300;
        margin-top: 20px;
      }

      a.hvr-sweep-to-right {
        @include sweeptoright;
        margin-top: 40px;

        &:hover {
          color: #fff;
        }
      }

      a.hvr-sweep-to-right:before{
        background-color: $primary-color-hover;
      }
    }
  }
}

/* ----- =Page ------ */

.page, .news {
  background-color: $bg-color-grey;

  // News sidebar
  .news-sidebar {
    float: left;
    width: 100%;

    h3 {
      background: transparent $linear-gradient repeat scroll 0 0;
      line-height: 40px;
      font-size: 22px;
      color: #fff;
      padding: 10px 25px;
      margin: 0;
    }

    .news-sidebar-content {
      background-color: #fff;
      padding: 20px 25px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
      float: left;
      width: 100%;

      a.news-sidebar-item {
        display: block;
        border: none;
        padding: 0 0 20px 0;
        margin-bottom: 20px;
        text-align: left;
        position: relative;
        float: left;
        border-bottom: 1px solid $bg-color-grey;

        &:last-child {
          margin-bottom: 0px;
          padding: 0;
          border-bottom: none;
        }

        .newsimage {
          width: 30%;
          height: 65px;
          margin-right: 15px;
          position: relative;
          overflow: hidden;
          display: block;
          float: left;

          &:before {
            @include overlay;
            background-color: rgba(0, 0, 0, 0);
          }

          img {
            height: 100%;
            max-width: 100%;
            object-fit: cover;
            position: relative;
            z-index: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            transition: ease 500ms;
            -webkit-backface-visibility: hidden;
          }
        }

        &:hover {
          text-decoration: none;

          .caption {
            h4 {
              color: $primary-color;
              text-decoration: none;
            }
          }

          img {
            transform: scale(1.2,1.2);
          }
        }

        .caption {
          background-color: #fff;
          padding: 0px;
          position: relative;
          z-index: 1;
          width: 65%;
          float: left;

          p {
            margin: 10px 0 0 0;
            font-size: 15px;
            color: $primary-color;
            font-weight: 600;
          }

          p.date {
            font-size: 14px;
            font-weight: 300;
            color: rgba(0,0,0,0.5);
            margin-top: 5px;
          }

          h4 {
            font-size: 16px;
            font-weight: 700;
            margin: 0px;
            color: $bg-color-black;
          }
        }
      }
    }
  }

  // Informatie sidebar

  .contact-sidebar {
    float: left;
    width: 100%;

    h3 {
      background: transparent $linear-gradient repeat scroll 0 0;
      line-height: 40px;
      font-size: 22px;
      color: #fff;
      padding: 10px 25px;
      margin: 0;
    }

    .contact-sidebar-content {
      background-color: #fff;
      padding: 10px 25px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
      float: left;
      width: 100%;

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
          line-height: 50px;
          border-bottom: 1px solid $bg-color-grey;

          &:before {
            display: none;
          }

          &:last-child {
            border: none;
          }

          i {
            margin-right: 10px;
            color: $primary-color;
            transition: $transition;
          }

          a:hover {
            text-decoration: none;
            i {
              margin-right: 5px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

// NGG Photo Gallery

.ngg-galleryoverview {
  margin: 10px 0 40px 0 !important;
  overflow: visible !important;

  .ngg-gallery-thumbnail-box {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
    margin: 15px 15px 0 0;
    width: 30%;

    .ngg-gallery-thumbnail {
      margin: 0 !important;
      border: 1px solid #fff !important;
    }
  }

  .ngg-navigation {
    padding-top: 20px;
    text-align: left;

    span {
      color: #fff;
      background-color: $primary-color;
      padding: 3px 9px 4px 9px;
      margin: 0 5px 0 0;
    }

    a.page-numbers, a.next, a.prev {
      background-color: #fff;
      margin-right: 5px;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.20);
      padding: 3px 9px 4px 9px;
      text-decoration: none;

      &:hover {
        background-color: $primary-color;
        box-shadow: none;
      }
    }
  }
}

@media screen and (max-width: 425px) {

  // NGG Photo Gallery

  .ngg-galleryoverview {
    margin-top: 10px !important;
    overflow: visible !important;

    .ngg-gallery-thumbnail-box {
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
      margin: 4% 4% 0 0;
      width: 46%;

      .ngg-gallery-thumbnail {
        margin: 0 !important;
        border: 1px solid #fff !important;
      }
    }
  }

  .page, .news {
    background-color: $bg-color-grey;

    // News sidebar
    .news-sidebar {
      margin-top: 20px;
      .news-sidebar-content {
        a.news-sidebar-item {
          &:last-child {
            margin-bottom: 0px;
            padding: 0;
            border-bottom: none;
          }

          .newsimage {
            width: 100%;
            height: 125px;
            margin-bottom: 15px;
            margin-right: 15px;
          }

          .caption {
            width: 100%;
          }
        }
      }
    }
  }
}

/* ----- =Breadcrums ------ */

  div.breadcrumb {
    margin: 5px 0 0 0;
    background-color: transparent;

    a {
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
      padding: 0 5px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);

      &:hover {
        color: #fff;
        text-decoration: underline;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      }
    }

    span {
      color: rgba(255,255,255,0.8);
      font-size: 16px;
      text-transform: uppercase;
      padding: 0 5px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    }
  }

  /* ================================
  =Footer
  ================================ */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 140px;
  line-height: 140px;
  background-color: #2a2a2a;
  background: rgba(0, 0, 0, 0) linear-gradient(to right, #2a2a2a 0%, #191919 100%) repeat scroll 0 0;

  p {
    line-height: 140px;
    margin: 0;
    font-weight: 300;
    color: #fff;
    text-align: center;
  }
}

@import "_footer-col-three";
@import "_responsive";

/* ================================
=Desktop
================================ */

section.video-fullwidth {
  background-color: transparent;
  background-position: center;
  background-size: cover;
  transform: translateX(-180deg);
  position: relative;
  height:600px;
  padding: 0;
  -webkit-backface-visibility: hidden;
  background-attachment: fixed;

  .picture-overlay {
    @include overlay;
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 600px;
    position: relative;
    z-index: 1;
    text-align: center;

    .inner-content {
      padding: 15px;
      text-align: center;

      h3 {
        color: #fff;
        margin: 0;
        padding: 0;
        line-height: 100%;
        font-family: $font-family-alt;
        width:100%;
        font-size: 42px;
        font-weight: 300;
        display: inline-block;
        position: relative;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
      }

      button.btn-video {
        background-color: transparent;
        border: none;
        padding: 17px 30px;
        font-size: 100px;
        color: #fff;
        opacity: 1;
        transition: $transition;

        &:hover, &:visited, &:focus {
          font-size: 120px;
          outline: none;
        }
      }

      a.hvr-sweep-to-right:before{
        background-color: $primary-color-hover;
      }
    }
  }
}

.modal {
  .modal-dialog {
    width: 700px;
    margin: 100px auto;

    .modal-content {
      border-radius: 0px;
      border: none;

      .modal-header {
        button.close {
          span {
            display: none;
          }

          i {
            color: $primary-color;
            opacity: 1;
            -webkit-tap-highlight-color: rgba(0,0,0,1);
          }
        }
      }
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  section.video-fullwidth {
    height: 300px;

    .picture-overlay {
      @include overlay;
    }

    .inner {
      height: 300px;

      .inner-content {
        button.btn-video {
          font-size: 55px;

          &:hover, &:visited, &:focus {
            font-size: 55px;
          }
        }
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  section.video-fullwidth {
    display: none;
  }

} /* End of view */
